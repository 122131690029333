@import "Sketch3.css";

:root {
    --drawer-width: calc(72vh + 80px);
}

.drawer {
    position: fixed;
    bottom: 0;
    width: var(--drawer-width);

    left: calc(((100% - (var(--sidenav-width))) / 2) - (var(--drawer-width) / 2) + (var(--sidenav-width)));

    filter: var(--accent-shadow);

    z-index: 3;
}

.drawer-handle {
    display: flex;
    justify-content: center;

    position: static;
    margin-left: auto;
    margin-right: auto;

    height: 30px;
    width: 80px;

    border-radius: 10px 10px 0 0;

    background-color: var(--pal-main);

    overflow: hidden;

    cursor: pointer;

    transform: translateY(1px);
}

.drawer-content {
    display: flex;
    justify-content: space-between;

    border-radius: 10px 10px 0 0;

    padding: 10px;
    background-color: var(--pal-main);
}

.caret {
    filter: var(--pal-icon-filter);
    transform: none;

    width: 18px;

    transition: 0.2s transform ease-in-out;
}

.collapsed .caret {
    transform: rotate(180deg);
}

.drawer button {
    background-color: var(--pal-cream);
    border-radius: 10px;
    padding: 10px;

    width: 18vh;
    height: 18vh;

    border: none;
    filter: var(--neutral-shadow);
}

.central-scroll-panel {
    position: fixed;

    height: 60vh;
    width: var(--drawer-width);

    left: calc(((100% - (var(--sidenav-width))) / 2) - (var(--drawer-width) / 2) + (var(--sidenav-width)));
    bottom: 28vh;

    border-radius: 10px;
    background-color: color-mix(in srgb, var(--pal-main) var(--pal-blend2), var(--pal-white));
    filter: var(--accent-shadow);

    display: flex;
    flex-direction: column;

    padding: 20px;
}

.panel-title {
    font-size: calc(2.5em + var(--acc-size-offset));
    font-family: 'Lexend', sans-serif;
    font-weight: calc(bold + var(--acc-bold-offset));

    text-align: center;
    padding-top: 10px;

    flex: 0 1 auto;

    filter: var(--main-shadow-small);
}

.panel-scroll {
    overflow-y: auto;
}

.panel-scroll::before {
    content: '';
    position: absolute;
    width: calc(100% - 40px - 18px);
    height: 27px;
    background: linear-gradient(color-mix(in srgb, var(--pal-main) var(--pal-blend2), var(--pal-white)), rgba(255, 255, 255, 0.001));
    z-index: 2;

    pointer-events: none;
}

.panel-scroll::after {
    content: '';
    position: absolute;
    width: calc(100% - 40px - 18px);
    height: 27px;
    background: linear-gradient(rgba(255, 255, 255, 0.001), color-mix(in srgb, var(--pal-main) var(--pal-blend2), var(--pal-white)));
    z-index: 2;

    bottom: 20px;

    pointer-events: none;
}

/* width */
.panel-scroll::-webkit-scrollbar {
    width: 18px;
    overflow: hidden;
}

/* Track */
.panel-scroll::-webkit-scrollbar-track {
    background: color-mix(in srgb, var(--pal-main) var(--pal-blend3), var(--pal-white));
    border-radius: 10px;
    /*box-shadow: inset 2px 0px 2px rgba(40, 44, 52, 0.2);*/
}

/* Handle */
.panel-scroll::-webkit-scrollbar-thumb {
    background: var(--pal-font);
    border-radius: 10px;

    border: 4px solid color-mix(in srgb, var(--pal-main) var(--pal-blend3), var(--pal-white));

    box-shadow: none;
}

.panel-scroll .bookmarks-entry {
    display: block;

    font-size: calc(1.5em + var(--acc-size-offset));
    font-family: 'Lexend', sans-serif;
    font-weight: calc(normal + var(--acc-bold-offset));

    text-align: center;

    flex: 0 1 auto;

    padding: 10px;

    border-radius: 10px;
}

.panel-scroll .bookmarks-entry:hover {
    background-color: color-mix(in srgb, var(--pal-main) var(--pal-blend1), var(--pal-white));
}

.cb-filter button.panel-close-button {
    margin: 20vh 0 0 20px;
    padding: 10px;
    background-color: var(--pal-main);

    width: 15vh;
    height: 15vh;

    border-radius: 10px;
    border: none;
    filter: var(--neutral-shadow-light);
}