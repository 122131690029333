@import "Sketch3.css";


.definable {
    display: inline;
    color: var(--pal-main);
    text-decoration-line: underline;

    visibility: visible;
    cursor: pointer;
}

.definable:hover {
    color: color-mix(in srgb, var(--pal-main) var(--pal-blend2), var(--pal-white));
}

.definition {
    visibility: hidden;
    font-weight: calc(normal + var(--acc-bold-offset));

    padding: 0;
    margin: 0;

    position: static;
}

.definition-hovered {
    visibility: visible;
    font-weight: calc(normal + var(--acc-bold-offset));

    filter: var(--neutral-shadow);

    /*transform: translate(0%, 40%);*/
}

.definition-hovered a{
    text-decoration-line: underline;
    color: color-mix(in srgb, var(--pal-link) var(--pal-blend2), var(--pal-white));
}

.def-box {
    position: absolute;
    width: 50vw;
    height: 300px;

    padding: 0;
    margin: 0;

    pointer-events: none;

    z-index: 5;
}

.def-blurb {
    text-align: left;
    padding: 8px;

    min-height: 120px;
    width: fit-content;
    height: fit-content;

    color: var(--pal-font);

    background-color: var(--pal-main);
    box-sizing: border-box;

    border-radius: 20px;

    pointer-events: auto;
    font-size: 16pt;
    line-height: 28pt;
}

.def-blurb * {
    font-size: calc(1em + var(--acc-size-offset));
    font-weight: calc(400 + var(--acc-bold-offset));;
}

.def-styled {
    fill: var(--pal-main);
    position: absolute;
}

.help-definition:focus-within .thinking {
    display: none;
}

.help-definition:not(:focus-within) .answering {
    display: none;
}